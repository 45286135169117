@use '@shared/util-styling/mixins';

.optimizely-edit-button {
  @include mixins.link-reset;

  background: hsl(227deg 100% 50%);
  border-radius: 0 0 4px 4px;
  border-top: none;
  box-shadow: 2px 2px 4px 1px rgb(0 55 255 / 32%);
  font-size: 16px;
  margin: 0;
  padding: 8px 16px;
  position: fixed;
  right: 70px;
  top: 0;
  z-index: var(--z-index-sticky);

  &:hover {
    background-color: hsl(227deg 100% 50%);
  }

  @include mixins.breakpoint('medium') {
    right: 10px;
  }
}

.optimizely-edit-button-link {
  display: inline-block;
  text-decoration: none;
}

.optimizely-edit-button-icon {
  height: 16px;
  width: 16px;
}

.optimizely-edit-button-text {
  color: #fff;
  padding-left: 4px;
}
